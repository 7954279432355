<template>
  <b-col cols="12" v-if="loading">
    <div class="text-center center" style="top: 0; right: 50">
      <span class="loader"></span>
      <h4>Loading, please wait...</h4>
    </div>
  </b-col>
  <b-col cols="12" v-else-if="isError">
    <div class="text-center center">
      <img
        src="@/assets/img/pages/error/warning.svg"
        alt="Error"
        style="max-height: 130px"
      />
      <h2>Oh no :(</h2>
      <p>
        We encountered an error while trying to retrieve your habitat.
        <br />
        If you have a habitat with Undisputed Noobs for less than 1 hour, please
        try again later.
        <br /><br />
        If you still can't access your habitat, please contact us on Discord.
        <br /><br />
        Error: {{ errorMessage }}
      </p>
    </div>
  </b-col>
  <b-col cols="12" v-else>
    <b-row class="align-self-stretch">
      <b-col class="col-lg-9 col-12 mb-32">
        <b-card class="h-100">
          <b-row>
            <b-col cols="12" md="3">
              <b-row align-v="start" align-h="between" class="mx-0">
                <b-col cols="12" class="text-center">
                  <img
                    class="rounded"
                    :src="habitatData.habitatImage"
                    alt="Your Habitat"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" md="9">
              <b-row>
                <b-col cols="12" sm="6">
                  <div class="my-8">
                    <h4 class="mb-10">
                      {{ habitatData.habitatName }}
                    </h4>
                    <b-badge
                      v-if="habitatElement == 'Earth'"
                      variant="success"
                      class="border-0 ml-2"
                      >Earth</b-badge
                    >
                    <b-badge
                      v-if="habitatElement == 'Fire'"
                      variant="danger"
                      class="border-0 ml-2"
                      >Fire</b-badge
                    >
                    <b-badge
                      v-if="habitatElement == 'Metal'"
                      variant="grey"
                      class="border-0 ml-2"
                      >Metal</b-badge
                    >
                    <b-badge
                      v-if="habitatElement == 'Water'"
                      variant="info"
                      class="border-0 ml-2"
                      >Water</b-badge
                    >
                    <b-badge
                      v-if="habitatElement == 'Wood'"
                      variant="warning"
                      class="border-0 ml-2"
                      >Wood</b-badge
                    >
                    <b-badge
                      v-if="habitatLevel == '1'"
                      variant="warning"
                      class="border-0 ml-2"
                      >Level 1</b-badge
                    >
                    <b-badge
                      v-if="habitatLevel == '2'"
                      variant="warning"
                      class="border-0 ml-2"
                      >Level 2</b-badge
                    >
                    <b-badge
                      v-if="habitatLevel == '3'"
                      variant="warning"
                      class="border-0 ml-2"
                      >Level 3</b-badge
                    >
                  </div>

                  <p
                    class="mb-0 hp-p1-body font-weight-normal text-black-80 hp-text-color-dark-30"
                  >
                    {{ habitatData.habitatDescription }}
                  </p>
                </b-col>

                <b-col cols="12" sm="6">
                  <div class="text-sm-right mt-24 mt-sm-0">
                    <span class="h3 mb-8 text-primary font-weight-medium">
                      {{ totalKiEarned }} Ki Earned
                      <p class="hp-caption mb-0 text-black-60 mb-8">
                        From the beginning
                      </p>
                    </span>

                    <div
                      v-if="subHabitat == 0"
                      class="d-flex align-items-center justify-content-end mb-4"
                    >
                      <i class="ri-close-circle-fill text-danger mr-4"></i>
                      <p class="mb-0 hp-text-color-dark-0">No 2nd habitat</p>
                    </div>
                    <div
                      v-if="subHabitat == 0"
                      class="d-flex align-items-center justify-content-end mb-4"
                    >
                      <i class="ri-close-circle-fill text-danger mr-4"></i>
                      <p class="mb-0 hp-text-color-dark-0">No 3rd habitat</p>
                    </div>

                    <div
                      v-if="subHabitat == 1"
                      class="d-flex align-items-center justify-content-end mb-4"
                    >
                      <i class="ri-checkbox-circle-fill text-success mr-4"></i>
                      <p class="mb-0 hp-text-color-dark-0">
                        2nd habitat assigned
                      </p>
                    </div>
                    <div
                      v-if="subHabitat == 1"
                      class="d-flex align-items-center justify-content-end mb-4"
                    >
                      <i class="ri-close-circle-fill text-danger mr-4"></i>
                      <p class="mb-0 hp-text-color-dark-0">No 3rd habitat</p>
                    </div>

                    <div
                      v-if="subHabitat == 2"
                      class="d-flex align-items-center justify-content-end mb-4"
                    >
                      <i class="ri-checkbox-circle-fill text-success mr-4"></i>
                      <p class="mb-0 hp-text-color-dark-0">
                        2nd habitat assigned
                      </p>
                    </div>
                    <div
                      v-if="subHabitat == 2"
                      class="d-flex align-items-center justify-content-end mb-4"
                    >
                      <i class="ri-checkbox-circle-fill text-success mr-4"></i>
                      <p class="mb-0 hp-text-color-dark-0">
                        3rd habitat assigned
                      </p>
                    </div>

                    <b-button
                      variant="outline-primary"
                      class="mt-24 mb-8"
                      block
                      @click="viewHabitat"
                    >
                      <i class="ri-external-link-line remix-icon mr-8"></i>
                      <span>View on Genopets</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <div class="divider divider-solid"></div>
            <b-col cols="12" lg="6" v-if="subHabitat >= 1">
              <b-row>
                <b-col cols="4">
                  <img
                    class="rounded shadow"
                    :src="subHabitats[0].subHabitatImage"
                    alt="Your 1st Sub Habitat"
                  />
                </b-col>
                <b-col cols="8">
                  <h4>{{ subHabitats[0].name }}</h4>
                  <b-badge
                    v-if="subHabitats[0].element == 'Earth'"
                    variant="success"
                    class="border-0 ml-2"
                    >Earth</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[0].element == 'Fire'"
                    variant="danger"
                    class="border-0 ml-2"
                    >Fire</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[0].element == 'Metal'"
                    variant="grey"
                    class="border-0 ml-2"
                    >Metal</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[0].element == 'Water'"
                    variant="info"
                    class="border-0 ml-2"
                    >Water</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[0].element == 'Wood'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Wood</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[0].level == '1'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Level 1</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[0].level == '2'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Level 2</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[0].level == '3'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Level 3</b-badge
                  >
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="6" v-if="subHabitat == 2">
              <b-row>
                <b-col cols="4">
                  <img
                    class="rounded shadow"
                    :src="subHabitats[1].subHabitatImage"
                    alt="Your 1st Sub Habitat"
                  />
                </b-col>
                <b-col cols="8">
                  <h4>{{ subHabitats[1].name }}</h4>
                  <b-badge
                    v-if="subHabitats[1].element == 'Earth'"
                    variant="success"
                    class="border-0 ml-2"
                    >Earth</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[1].element == 'Fire'"
                    variant="danger"
                    class="border-0 ml-2"
                    >Fire</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[1].element == 'Metal'"
                    variant="grey"
                    class="border-0 ml-2"
                    >Metal</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[1].element == 'Water'"
                    variant="info"
                    class="border-0 ml-2"
                    >Water</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[1].element == 'Wood'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Wood</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[1].level == '1'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Level 1</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[1].level == '2'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Level 2</b-badge
                  >
                  <b-badge
                    v-if="subHabitats[1].level == '3'"
                    variant="warning"
                    class="border-0 ml-2"
                    >Level 3</b-badge
                  >
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="6" v-else-if="subHabitat == 1">
              <h4 class="text-center">No 3rd Habitat Assigned</h4>
            </b-col>
            <b-col cols="12" v-else>
              <h4 class="text-center">No Sub Habitat Assigned</h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="col-lg-3 col-12 mb-32">
        <b-card class="h-100">
          <b-row align-h="between">
            <b-col>
              <h4 class="mb-0">Harvest Efficiency</h4>
            </b-col>

            <b-col cols="6" class="hp-flex-none w-auto">
              <i
                v-b-tooltip.hover
                title="Here we measure your harvest efficiency based on your harvest count and other data. We expect your efficiency to be equal to or greater than 75%. If you are below this level, contact our team to find out how to improve it."
                class="ri-information-line hp-text-color-dark-0 remix-icon"
                style="font-size: 24px"
              ></i>
              <b-tooltip triggers="hover" placement="bottom" class="hp-tooltip">
                <p class="mb-0 hp-text-color-dark-0">
                  Harvest Efficency is the percentage of Ki you earn from
                  harvesting your habitat.
                </p>
              </b-tooltip>
            </b-col>
          </b-row>

          <div class="overflow-hidden">
            <apexchart
              type="radialBar"
              height="335"
              legend="legend"
              :options="efficencyChart.options"
              :series="efficencyChart.series"
            ></apexchart>
            <div class="text-center">
              {{ efficencyChart.efficencyType }}
            </div>
            <b-row align-h="between" class="text-center pt-32">
              <b-col cols="6">
                <h4 class="mb-0">First Harvest</h4>
                <p class="mb-0 text-black-80 hp-text-color-dark-30">
                  {{ user.firstHarvest }}
                </p>
              </b-col>

              <b-col cols="6">
                <h4 class="mb-0">Last Harvest</h4>
                <p class="mb-0 text-black-80 hp-text-color-dark-30">
                  {{ user.lastHarvest }}
                </p>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-lg-6 col-12 mb-32" v-if="!temporaryHide">
        <b-card class="h-100 border-1">
          <b-row align-h="between" class="mb-16">
            <b-col>
              <div style="display: flex; flex-direction: row">
                <img
                  src="https://cdn.discordapp.com/avatars/971788258033950720/1a075120a63df1c881339d5e3b4b0c81.png?size=1024"
                  alt="Lilly"
                  class="avatar avatar-sm mr-8"
                  style="max-height: 40px"
                />
                <h4 class="mt-2">What do we expect from you?</h4>
              </div>
            </b-col>

            <b-col cols="6" class="hp-flex-none w-auto">
              <i
                v-b-tooltip.hover
                title="
                Hello! I'm Lilly 😀
                I'm designed to help you. I still need to learn but I have already been able to calculate this for you!
                "
                class="ri-information-line hp-text-color-dark-0 remix-icon"
                style="font-size: 24px"
              ></i>
              <b-tooltip triggers="hover" placement="bottom" class="hp-tooltip">
                <p class="mb-0 hp-text-color-dark-0">
                  Harvest Efficency is the percentage of Ki you earn from
                  harvesting your habitat.
                </p>
              </b-tooltip>
            </b-col>
          </b-row>
          <p>
            Depending on the level of your habitat(s) you need to harvest a
            specific number of Ki and walk a certain number of steps each day.
            No worries, I have calculated everything for you! 🤗
          </p>
          <b-row class="mt-0">
            <b-col cols="12" lg="4">
              <b-input-group class="mt-2">
                <b-input-group-prepend is-text>
                  <a style="min-width: 100px" class="text-white text-left">
                    Genopet Level
                  </a>
                </b-input-group-prepend>
                <b-form-input
                  type="number"
                  v-model="user.genopet_level"
                  min="1"
                  max="100"
                  debounce="2500"
                ></b-form-input>
              </b-input-group>
              <p class="mt-6 mb-0 text-black-60 text-center">
                You can update your Genopet level at any time here or in
                <b-link to="/profile/inventory">your inventory</b-link>.
              </p>
            </b-col>
            <b-col cols="12" lg="8">
              <b-table :items="excpectationData" show-empty responsive>
                <template #cell(Minimum%20Ki%20Per%20Day)="row">
                  {{ row }} test
                </template>
              </b-table>
            </b-col>
          </b-row>
          <div
            class="hp-header-text-info hp-header-start-text d-flex mt-0 mb-0"
          >
            <span>
              <b>Pro tip:</b> Find more calculations and information about your
              habitat in the "Calculator" tab.
            </span>
          </div>
        </b-card>
      </b-col>
      <!--<b-col class="col-lg-6 col-12 mb-32" v-else>
        <h4 class="text-center" c>Something is coming soon here 👀</h4>
      </b-col>-->
      <b-col class="col-lg-6 col-12 mb-32">
        <b-card>
          <b-row align-h="between" class="mb-16">
            <b-col>
              <h4>Your harvests</h4>
            </b-col>
          </b-row>
          <b-table
            :items="harvestData"
            :current-page="currentPage"
            :per-page="perPage"
            stacked="md"
            show-empty
            borderless
            small
          >
            <template #cell(#)="row">
              {{ totalHarvest - row.index - (currentPage - 1) * perPage }}
            </template>
          </b-table>

          <b-row class="mt-16 align-items-center justify-content-end">
            <b-col class="hp-flex-none w-auto">
              <b-form-group
                label-for="per-page-select"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col class="hp-flex-none w-auto">
              <b-pagination
                align="end"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BBadge,
  BButton,
  BTable,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BFormSelect,
  BFormCheckboxGroup,
  BFormCheckbox,
  BPagination,
  BModal,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BThead,
  BTh,
  BLink,
  BAlert,
} from "bootstrap-vue";
import userService from "../../../../services/user.service";
import GenopetsUser from "../../../../services/genopets-user.services";
import axios from "axios";
import genopetsUserServices from "../../../../services/genopets-user.services";
import calc_data from "./calc_data.json";

export default {
  data() {
    return {
      temporaryHide: true,
      loading: true,
      isError: false,
      errorMessage: "",
      userUuid: "",
      wishCheck: true,
      habitatData: [],
      habitatElement: null,
      habitatLevel: null,
      habitatsLevel: [],
      habitatAddress: null,
      habitatSelected: null,
      subHabitats: [],
      subHabitat: 0,
      subHabitatData: null,
      harvestData: [],
      totalHarvest: 0,
      totalKiEarned: 0,
      efficencyChart: {
        series: [0],
        efficencyType: "Unknow",
        options: {
          chart: {
            fontFamily: "Manrope, sans-serif",
            type: "radialBar",
            id: "energy-card",

            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },

          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              track: {
                background: "transparent",
              },
              dataLabels: {
                name: {
                  show: true,
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#636E72",
                },
                value: {
                  fontSize: "24px",
                  fontWeight: "500",
                  color: undefined,
                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },
            },
          },

          stroke: {
            dashArray: 6,
          },
          labels: ["Includes Sub Habitats"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 1,
              gradientToColors: ["#0010F7", "#1BE7FF"],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
        },
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      user: {
        firstHarvest: "Unknown",
        lastHarvest: "Unknown",
        genopet_level: 1,
        genopet_xp: 0,
        genopet_steps: 0,
        harvestEfficiencyCount: {
          perfect: 0,
          good: 0,
          not_good: 0,
          missed: 0,
        },
      },
      mathData: {
        kiPerLevel: {
          level1: {
            min: 15,
            max: 30,
          },
          level2: {
            min: 22.5,
            max: 50,
          },
          level3: {
            min: 27.5,
            max: 70,
          },
        },
        userAskedHarvestAmount: {
          min: 15,
          max: 30,
        },
      },
      excpectationData: [
        {
          " ": "Minimum",
          "Energy /Day": 0,
          "Steps /Day": 0,
          "Ki /Day": 0,
        },
        {
          " ": "Recommended",
          "Energy /Day": 0,
          "Steps /Day": 0,
          "Ki /Day": 0,
        },
      ],
      inventory: [],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BBadge,
    BButton,
    BTable,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormCheckbox,
    BPagination,
    BModal,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BThead,
    BTh,
    BLink,
    BAlert,
  },
  methods: {
    changeTab(tab) {
      this.$emit("changeTab", tab);
    },
    wishToggle() {
      this.wishCheck = !this.wishCheck;
    },
    viewHabitat() {
      window.open(
        "https://mainframe.genopets.me/inventory?cat=habitats&habitat-cat=harvesting&habitatMint=" +
          this.habitatAddress,
        "_blank"
      );
    },
  },
  async mounted() {
    var username = this.$store.state.auth.user.username;
    var useruuid = this.$store.state.auth.user.useruuid;
    this.userUuid = useruuid;

    var userSolanaWallet = await userService
      .getWallets(username, useruuid)
      .then((response) => {
        return response.data.walletSolana;
      });

    //var userSolanaWallet = "7ebkQUqoJAEsR8UC4Rb78xabwQDhTn2Kytjfw6uAeufb";
    //var userSolanaWallet = "DxDZctVSGXwZeH89XQVUsUpFLv3Q4tGZSTda4YG3Zzme";

    //var userSolanaWallet = "8uwKJF8TUSyz5v3sc6oKg8yni6N1J9Vs7kTvfyP23RT8";

    // 2 sub - 7ebkQUqoJAEsR8UC4Rb78xabwQDhTn2Kytjfw6uAeufb
    await GenopetsUser.getUserHabitat(userSolanaWallet).then(
      async (response) => {
        //console.log(response);
        if (response.success) {
          ////console.log(response);
          this.habitatData = response.habitatData;
          this.totalRows = response.harvestData.length;
          this.habitatAddress = response.habitatData.habitatId;
          this.habitatSelected = response.habitatData.habitatId;

          var habitatAttributes = response.habitatData.habitatAttributes;
          var oldestHarvest;
          var newestHarvest;
          var currentDateTimestamp = new Date().getTime();

          /*this.totalKiEarned =
          response.habitatData.habitatAttributes[10]["Total Ki Earned"];*/

          for (var i in habitatAttributes) {
            if (habitatAttributes[i]["Element"]) {
              this.habitatElement = habitatAttributes[i]["Element"];
            }
            if (habitatAttributes[i]["Level"]) {
              this.habitatLevel = habitatAttributes[i]["Level"];
              this.habitatsLevel.push(habitatAttributes[i]["Level"]);
            }
            if (habitatAttributes[i]["Sub Habitats"]) {
              this.subHabitat = JSON.parse(
                habitatAttributes[i]["Sub Habitats"]
              ).length;

              var subHabitatsAttributes = JSON.parse(
                habitatAttributes[i]["Sub Habitats"]
              );

              for (var j in subHabitatsAttributes) {
                var subHabitatId = subHabitatsAttributes[j].mint;
                var subHabitatMetadata = await axios
                  .get("https://api.genopets.me/habitat/" + subHabitatId)
                  .then((response) => {
                    return response.data;
                  });
                var subHabitatLevel = 1;

                var subHabitatType = "Unknown";
                var subHabitatAttributes = subHabitatMetadata.attributes;
                for (var x in subHabitatAttributes) {
                  if (subHabitatAttributes[x].trait_type == "Level") {
                    subHabitatLevel = subHabitatAttributes[x].value;
                    this.habitatsLevel.push(subHabitatAttributes[x].value);
                  }
                  if (subHabitatAttributes[x].trait_type == "Element") {
                    subHabitatType = subHabitatAttributes[x].value;
                  }
                }

                this.subHabitats.push({
                  tokenId: subHabitatId,
                  name: subHabitatMetadata.name,
                  level: subHabitatLevel,
                  element: subHabitatType,
                  subHabitatImage: subHabitatMetadata.image,
                });
              }
            }
          }
          for (var i in this.habitatsLevel) {
            var level = this.habitatsLevel[i];
            var genopetLevel = this.user.genopet_level;

            if (level == 1) {
              this.excpectationData[0]["Ki /Day"] +=
                this.mathData.kiPerLevel.level1.min;
              this.excpectationData[1]["Ki /Day"] +=
                this.mathData.kiPerLevel.level1.max;
            } else if (level == 2) {
              this.excpectationData[0]["Ki /Day"] +=
                this.mathData.kiPerLevel.level2.min;
              this.excpectationData[1]["Ki /Day"] +=
                this.mathData.kiPerLevel.level2.max;
            } else if (level == 3) {
              this.excpectationData[0]["Ki /Day"] +=
                this.mathData.kiPerLevel.level3.min;
              this.excpectationData[1]["Ki /Day"] +=
                this.mathData.kiPerLevel.level3.max;
            }
          }

          var needMinimumCalculation = false;
          var needMaximumCalculation = false;

          this.excpectationData[0]["Energy /Day"] = Math.round(
            this.excpectationData[0]["Ki /Day"] / 0.02
          );
          this.excpectationData[1]["Energy /Day"] = Math.round(
            this.excpectationData[1]["Ki /Day"] / 0.02
          );

          if (this.excpectationData[0]["Energy /Day"] < 1000) {
            this.excpectationData[0]["Steps /Day"] =
              this.excpectationData[0]["Energy /Day"];
          } else {
            needMinimumCalculation = true;
          }
          if (this.excpectationData[1]["Energy /Day"] < 1000) {
            this.excpectationData[1]["Steps /Day"] =
              this.excpectationData[0]["Energy /Day"];
          } else {
            needMaximumCalculation = true;
          }

          for (var i in response.harvestData) {
            var date = response.harvestData[i].timestamp;
            var harvestDate = response.harvestData[i].unlockTime;

            // Translate harvestDate from unix to human readable
            harvestDate = new Date(harvestDate * 1000).toLocaleString("en-GB");

            var KiHarvested = response.harvestData[i].kiAmount / 1000000000;

            var thisHarvestEfficiency;
            if (
              KiHarvested == this.excpectationData[1]["Ki /Day"] ||
              KiHarvested > this.excpectationData[1]["Ki /Day"]
            ) {
              thisHarvestEfficiency = "Perfect!";
              this.user.harvestEfficiencyCount.perfect++;
            } else if (
              KiHarvested < this.excpectationData[1]["Ki /Day"] &&
              KiHarvested > this.excpectationData[0]["Ki /Day"]
            ) {
              thisHarvestEfficiency = "Good";
              this.user.harvestEfficiencyCount.good++;
            } else {
              thisHarvestEfficiency = "Not Good";
              this.user.harvestEfficiencyCount.not_good++;
            }

            var toPush = {
              "#": "",
              "Harvest Date": new Date(date).toLocaleString("en-GB"),
              "Ki Amount": KiHarvested,
              "Claimable On": harvestDate,
            };
            this.harvestData.push(toPush);
            this.totalHarvest++;

            this.totalKiEarned += response.harvestData[i].kiAmount / 1000000000;

            if (i == 0) {
              newestHarvest = date;
            } else if (i == response.harvestData.length - 1) {
              oldestHarvest = date;
            }
          }

          if (!response.harvestData) {
            this.isError = true;
            this.errorMessage =
              "No harvest data found for this habitat, please harvest at least once to see data.";
            this.totalKiEarned = 0;
          } else {
            var efficency = await genopetsUserServices
              .getHarvestEfficiency(response.harvestData, userSolanaWallet)
              .then((response) => {
                console.log(response);
                if (response.success) {
                  this.efficencyChart.series = [
                    Math.round(response.harvestEfficiency),
                  ];

                  this.user.firstHarvest = new Date(
                    response.latestHarvest
                  ).toLocaleString("en-GB");

                  this.user.lastHarvest = new Date(
                    response.lastHarvest
                  ).toLocaleString("en-GB");

                  this.user.harvestEfficiencyCount.missed =
                    response.harvestMissed;

                  return response.harvestEfficiency;
                } else {
                  this.isError = true;
                  this.errorMessage = response.message;
                }

                return 0;
              });

            if (efficency < 45) {
              this.efficencyChart.efficencyType =
                "Warning, you might lose your habitat!";
            } else if (efficency < 50) {
              this.efficencyChart.efficencyType =
                "Not bad, but you can do better!";
            } else if (efficency < 75) {
              this.efficencyChart.efficencyType = "Good, keep it up!";
            } else if (efficency < 100) {
              this.efficencyChart.efficencyType =
                "Great, you are doing amazing!";
            } else if (efficency == 100) {
              this.efficencyChart.efficencyType = "Perfect, you are a pro!";
            } else if (efficency > 100) {
              this.efficencyChart.efficencyType =
                "Ok humm no you need to tell me how you do that";
            } else {
              this.efficencyChart.efficencyType = "Something went wrong";
            }
          }

          console.log(response.harvestMissed);

          this.loading = false;
        } else {
          this.isError = true;
          this.errorMessage = response.message;
          this.loading = false;
        }
      }
    );

    await userService.getInventory(useruuid).then((res) => {
      this.inventory.push(res.data.genopet);

      this.user.genopet_level = res.data.genopet.level;
    });

    this.$watch("user.genopet_level", (newVal, oldVal) => {
      if (newVal != oldVal) {
        var newData = {
          code: this.inventory[0].code,
          level: newVal,
          description: this.inventory[0].description,
          game: this.inventory[0].game,
          image: this.inventory[0].image,
          name: this.inventory[0].name,
          redemption_date: this.inventory[0].redemption_date,
        };

        userService
          .updateInventory(useruuid, "genopet", newData)
          .then((res) => {
            var success = res.success;
            if (success) {
              this.$bvToast.toast("Genopet level updated!", {
                title: "Success!",
                solid: true,
              });
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            }
          })
          .catch((err) => {
            this.$bvToast.toast("Something went wrong!", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          });
      }
    });
  },
};
</script>

<style scoped lang="scss">
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
