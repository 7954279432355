<template>
  <div class="hp-faq-tabs bg-white hp-bg-dark-100 rounded py-12 px-lg-24 px-0">
    <b-nav pills class="hp-overflow-x-auto flex-nowrap">
      <b-nav-item
        class="mr-lg-4 mr-0 d-flex align-items-center"
        :link-classes="`py-4${propsTabIndex.index == index ? ' active' : ''}${
          item.locked ? ' locked' : ''
        }${item.locked && item.title == 'Admin' ? ' hide' : ''}`"
        :key="index"
        v-for="(item, index) in navbarItems"
        @click="tabMenu(index)"
        :disabled="item.locked"
      >
        <span
          class="d-flex align-items-center text-black-100 hp-text-color-dark-0 hp-transition"
        >
          <i
            class="text-black-80 hp-transition mr-12"
            :class="item.icon"
            style="font-size: 20px"
          ></i>

          {{ item.title }}
        </span>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import { BCol, BNav, BNavItem, BDropdown, BDropdownItem } from "bootstrap-vue";

import dataJson from "./data.json";
import navbarData from "./navbar.js";
import userService from "../../../../services/user.service";

export default {
  props: ["propsTabIndex"],
  data() {
    return {
      data: dataJson,
      navbarItems: navbarData,
      isOnboarded: false,
      isAdmin: false,
    };
  },
  components: {
    BCol,
    BNav,
    BNavItem,
    BDropdown,
    BDropdownItem,
  },
  methods: {
    tabMenu(val) {
      this.$emit("tabMenuChange", val);
    },
  },
  mounted() {
    ////console.log(this.navbarItems);
    var username = this.$store.state.auth.user.username;
    var useruuid = this.$store.state.auth.user.useruuid;
    var portalRole = this.$store.state.auth.user.portalRole;

    if (portalRole != "user") {
      this.isAdmin = true;
    }

    userService.getUserApplication(username, useruuid).then(
      (response) => {
        if (response.data.gamesOnboardedFor.includes("genopets")) {
          this.isOnboarded = true;
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.locked {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.hide {
  display: none;
}
</style>
