<template>
  <b-col cols="12" class="mb-32">
    <b-row>
      <b-col class="col-sm-3 col-12 mb-12">
        <b-card>
          <b-row>
            <b-col class="hp-flex-none w-auto">
              <b-avatar
                class="bg-primary-4 hp-bg-color-dark-primary"
                size="48px"
              >
                <i class="ri-home-3-line" style="font-size: 24px"></i>
              </b-avatar>
            </b-col>

            <b-col class="pl-0">
              <h3 class="mb-4 mt-8">
                {{ widgets.totalHabitats
                }}<span class="hp-badge-text ml-8 text-primary"></span>
              </h3>

              <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
                Total Habitats (including subs)
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="col-sm-3 col-12 mb-12">
        <b-card>
          <b-row>
            <b-col class="hp-flex-none w-auto">
              <b-avatar
                class="bg-primary-4 hp-bg-color-dark-primary"
                size="48px"
              >
                <i class="ri-home-heart-line" style="font-size: 24px"></i>
              </b-avatar>
            </b-col>

            <b-col class="pl-0">
              <h3 class="mb-4 mt-8">
                {{ widgets.assignedHabitats
                }}<span class="hp-badge-text ml-8 text-primary"></span>
              </h3>

              <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
                Assigned Habitats
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="col-sm-3 col-12 mb-12">
        <b-card>
          <b-row>
            <b-col class="hp-flex-none w-auto">
              <b-avatar
                class="bg-primary-4 hp-bg-color-dark-primary"
                size="48px"
              >
                <i class="ri-home-smile-line" style="font-size: 24px"></i>
              </b-avatar>
            </b-col>

            <b-col class="pl-0">
              <h3 class="mb-4 mt-8">
                {{ widgets.subHabitats
                }}<span class="hp-badge-text ml-8 text-primary"></span>
              </h3>

              <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
                Sub Habitats
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="col-sm-3 col-12 mb-12">
        <b-card>
          <b-row>
            <b-col class="hp-flex-none w-auto">
              <b-avatar
                class="bg-primary-4 hp-bg-color-dark-primary"
                size="48px"
              >
                <i class="ri-home-2-line" style="font-size: 24px"></i>
              </b-avatar>
            </b-col>

            <b-col class="pl-0">
              <h3 class="mb-4 mt-8">
                {{ widgets.emptyHabitats
                }}<span class="hp-badge-text ml-8 text-primary"></span>
              </h3>

              <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
                Empty Habitats
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-lg-6 col-12" />
      <b-col class="col-lg-6 col-12">
        <b-form-group
          label="Search anything in the table"
          label-for="filter-input"
          label-cols-sm="5"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filterSearch"
              type="search"
              placeholder="Type to Search"
              :disabled="filter"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filterSearch"
                @click="filterSearch = ''"
                variant="outline-primary"
              >
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      :items="habitatsData"
      :fields="habitatsFields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter || filterSearch"
      :filter-included-fields="filterOn || null"
      stacked="md"
      responsive
      class="overflow-auto"
      :busy="isLoading"
    >
      <template #table-busy>
        <div class="hp-p1-body text-center my-2">
          <b-spinner class="align-middle mr-8"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>

    <b-row class="mt-16 align-items-center justify-content-end">
      <b-col class="hp-flex-none w-auto">
        <b-form-group label-for="per-page-select" label-size="sm" class="mb-0">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col class="hp-flex-none w-auto">
        <b-pagination
          align="end"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BTable,
  BCard,
  BFormGroup,
  BFormSelect,
  BPagination,
  BSpinner,
  BAvatar,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import genopetsAdminServices from "../../../../services/genopets-admin.services";

export default {
  data() {
    return {
      username: this.$store.state.auth.user.username,
      habitatsData: [],
      habitatsFields: [
        {
          key: "habitatName",
          label: "Habitat #",
        },
        { key: "habitatElement", label: "Element" },
        { key: "habitatLevel", label: "Level" },
        { key: "userDiscordUsername", label: "Assigned to" },
        { key: "userSolanaWallet", label: "User wallet" },
        { key: "harvestEfficiency", label: "Harvest Efficiency" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, { value: 500, text: "Show a lot" }],
      isLoading: true,
      widgets: {
        totalHabitats: 0,
        assignedHabitats: 0,
        subHabitats: 0,
        emptyHabitats: 0,
        errors: 0,
        notregisteredHabitats: 0,
      },
      filter: null,
      filterOn: [],
      filterSearch: null,
    };
  },
  components: {
    BRow,
    BCol,
    BAlert,
    BTable,
    BCard,
    BFormGroup,
    BFormSelect,
    BPagination,
    BSpinner,
    BAvatar,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  computed: {
    sortOptions() {
      return this.items.filter((item) => {
        let keep = true;

        // This is a basic equality filter. What I did in the actual code was to have an object with filter functions for each key. If a key was missing, it defaulted to straight equality.
        this.fieldKeys.forEach((key) => {
          keep =
            keep &&
            (this.selectVal[key] === undefined ||
              item[key] === this.selectVal[key]);
        });

        return keep;
      });
    },
    fieldKeys() {
      return Object.keys(this.items[0]);
    },
    options() {
      // This could be simplified if the select was it's own component.
      const options = {};
      this.fieldKeys.forEach((key) => {
        const vals = this.items.map((item) => item[key]);

        // Cheap and efficient unique.
        options[key] = Array.from(new Set(vals));
      });

      return options;
    },
  },
  async mounted() {
    var habitats = await genopetsAdminServices
      .getHabitats()
      .then((response) => {
        //console.log(response);
        return response;
      });
    for (var i in habitats) {
      this.widgets.totalHabitats++;
      var newestHarvest;
      var oldestHarvest;
      var currentDateTimestamp = new Date().getTime();
      var habitatElement;
      var habitatLevel;
      var habitatAttributes = habitats[i].habitatAttributes;

      if (!habitats[i].habitatHarvester) {
        this.widgets.emptyHabitats++;
        for (var j in habitatAttributes) {
          if (habitatAttributes[j]["Element"]) {
            habitatElement = habitatAttributes[j]["Element"];
          }

          if (habitatAttributes[j]["Level"]) {
            habitatLevel = habitatAttributes[j]["Level"];
          }
        }
        this.habitatsData.push({
          habitatName: habitats[i].habitatName,
          habitatElement: habitatElement,
          habitatLevel: habitatLevel,
          userSolanaWallet: "Not assigned",
          harvestEfficiency: "No data",
        });
        continue;
      } else {
        this.widgets.assignedHabitats++;

        for (var j in habitatAttributes) {
          if (habitatAttributes[j]["Sub Habitats"]) {
            var subHabitatCount = JSON.parse(
              habitatAttributes[j]["Sub Habitats"]
            ).length;
            this.widgets.subHabitats += subHabitatCount;
            this.widgets.totalHabitats += subHabitatCount;
          }

          if (habitatAttributes[j]["Element"]) {
            habitatElement = habitatAttributes[j]["Element"];
          }

          if (habitatAttributes[j]["Level"]) {
            habitatLevel = habitatAttributes[j]["Level"];
          }
        }
      }

      if (!habitats[i].harvestData) {
        this.habitatsData.push({
          habitatName: habitats[i].habitatName,
          habitatElement: habitatElement,
          habitatLevel: habitatLevel,
          userSolanaWallet: habitats[i].habitatHarvester,
          harvestEfficiency: "No data",
        });
        continue;
      }

      for (var j in habitats[i].userHarvestData) {
        var date = habitats[i].userHarvestData[j].timestamp;

        if (j == 0) {
          newestHarvest = date;
        } else if (j == habitats[i].userHarvestData.length - 1) {
          oldestHarvest = date;
        }
      }

      // Calculate the number of days between the oldest and newest harvest
      var daysBetweenHarvests =
        (currentDateTimestamp - oldestHarvest) / 1000 / 60 / 60 / 24 - 1;

      // Calculate the number of days between the newest harvest and now
      var daysSinceLastHarvest =
        (currentDateTimestamp - newestHarvest) / 1000 / 60 / 60 / 24 + 1;

      daysBetweenHarvests = Math.round(daysBetweenHarvests);
      daysSinceLastHarvest = Math.round(daysSinceLastHarvest);

      // Calculate the efficency of the habitat in percent (from 0 to 100)
      var efficency =
        (habitats[i].userHarvestData.length / daysBetweenHarvests) * 100 -
        (daysSinceLastHarvest / daysBetweenHarvests) * 100;

      this.habitatsData.push({
        habitatName: habitats[i].habitatMetadata.name,
        habitatElement: habitatElement,
        habitatLevel: habitatLevel,
        userDiscordUsername: habitats[i].userDiscordData.discordUsername,
        userSolanaWallet: habitats[i].userSolanaWallet,
        harvestEfficiency: Math.round(efficency) + "%",
      });
      this.widgets.assignedHabitats++;
    }

    this.totalRows = habitats.length || 0;

    this.isLoading = false;
  },
};
</script>
