var portalRole = JSON.parse(localStorage.getItem("user")).portalRole;
var onboardedFor = JSON.parse(localStorage.getItem("user")).onboardedFor;

var onboarded = false;
var isAdmin = false;

var menu;

if (onboardedFor.includes("genopets")) {
  onboarded = true;
}
if (portalRole != "user") {
  isAdmin = true;
}

if (!onboarded && !isAdmin) {
  menu = [
    {
      title: "About",
      icon: "ri-information-line",
    },
    {
      title: "How it works",
      icon: "ri-newspaper-line",
    },
    {
      title: "Dashboard",
      icon: "ri-dashboard-line",
      locked: true,
    },
    {
      title: "Admin",
      icon: "ri-shield-user-line",
      locked: true,
    },
  ];
} else if (onboarded && !isAdmin) {
  menu = [
    {
      title: "About",
      icon: "ri-information-line",
    },
    {
      title: "How it works",
      icon: "ri-newspaper-line",
    },
    {
      title: "Dashboard",
      icon: "ri-dashboard-line",
      locked: false,
    },
    {
      title: "Calculator",
      icon: "ri-calculator-line",
      locked: false,
    },
    {
      title: "Admin",
      icon: "ri-shield-user-line",
      locked: true,
    },
  ];
} else if (!onboarded && isAdmin) {
  menu = [
    {
      title: "About",
      icon: "ri-information-line",
    },
    {
      title: "How it works",
      icon: "ri-newspaper-line",
    },
    {
      title: "Dashboard",
      icon: "ri-dashboard-line",
      locked: true,
    },
    {
      title: "Calculator",
      icon: "ri-calculator-line",
      locked: false,
    },
    {
      title: "Admin",
      icon: "ri-shield-user-line",
      locked: false,
    },
    {
      title: "Treasury",
      icon: "ri-money-dollar-circle-line",
      locked: false,
    },
  ];
} else if (onboarded && isAdmin) {
  menu = [
    {
      title: "About",
      icon: "ri-information-line",
    },
    {
      title: "How it works",
      icon: "ri-newspaper-line",
    },
    {
      title: "Dashboard",
      icon: "ri-dashboard-line",
      locked: false,
    },
    {
      title: "Calculator",
      icon: "ri-calculator-line",
      locked: false,
    },
    {
      title: "Admin",
      icon: "ri-shield-user-line",
      locked: false,
    },
  ];
}

export default menu;
