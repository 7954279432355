<template>
  <b-col cols="12" class="mb-32">
    <b-alert variant="info" show class="text-left" style="font-size: 15px">
      <div
        class="hp-header-text-info hp-header-start-text d-flex align-items-center"
      >
        <div class="d-flex mr-12" style="max-width: 50px">
          <i class="ri-error-warning-line" style="zoom: 150%"></i>
        </div>

        <span
          >This page is under construction, in the meantime you can already use
          a community made calculator.</span
        >
      </div>
    </b-alert>
    <h4>Genopets Calculator.</h4>
    <b-row>
      <b-col class="col-lg-4 col-12">
        <b-card class="overflow-hidden border-1">
          <p>
            <b>This video guide will show you how to use the calculator:</b>
          </p>

          <b-embed type="video" aspect="16by9" controls>
            <source
              src="https://sacul.cloud/img/3474808909.mp4"
              type="video/mp4"
            />
          </b-embed>
          <p></p>
          <b-button
            variant="primary"
            href="https://docs.google.com/spreadsheets/d/1koUvv6tXODXxNREB6qzNVUab7Dt_NbWecraxJi8f1Kw/"
            target="_blank"
            class="btn-block"
            >Open the Calculator</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BFormInput,
  BCard,
  BCardImg,
  BButtonGroup,
  BEmbed,
  BAlert,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BFormInput,
    BCard,
    BCardImg,
    BButtonGroup,
    BEmbed,
    BAlert,
  },
};
</script>
