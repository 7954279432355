<template>
  <b-row>
    <b-col class="col-12">
      <b-alert variant="warning" show class="text-left" style="font-size: 15px">
        <div
          class="hp-header-text-info hp-header-start-text d-flex align-items-center"
        >
          <div class="d-flex mr-12" style="max-width: 50px">
            <i class="ri-error-warning-line" style="zoom: 150%"></i>
          </div>

          <span
            >New update of the game mechanics, please take the time to read the
            <a
              href="https://whitepaper.genopets.me/game-mechanics/mechanics-changelog"
              target="_blank"
              >Mechanics Changelog from Genopets</a
            >.</span
          >
        </div>
      </b-alert>
    </b-col>
    <b-col class="col-lg-6 col-12 mb-32 mt-32">
      <b-badge variant="warning" class="mb-6 border-1"
        >Page is in beta!</b-badge
      >
      <h1 class="mb-0">Genopets</h1>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Genopets is an alternate reality, massive multiplayer online game that
        gamifies physical, mental, and emotional health with financial and
        social rewards through non-fungible tokens.
      </h5>
      <div class="justify-content-center mt-32">
        <b-button
          variant="none"
          class="hp-border-color-dark-30 hp-hover-border-color-dark-primary-2"
          @click.prevent="discordLogin()"
          disabled
        >
          <i class="ri-user-add-line remix-icon"></i>
          <span>Register</span>
        </b-button>
      </div>
    </b-col>
    <b-col class="col-lg-6 col-12 mb-32">
      <img
        src="https://chaindebrief.com/wp-content/uploads/2021/10/GenoPets-FI-1024x538.jpeg"
        alt="Genopets"
        class="img-fluid rounded shadow w-50 float-right mobile"
      />
    </b-col>
    <b-col class="col-12 mb-32">
      <tab-menu :propsTabIndex="{ index }" @tabMenuChange="tabMenu" />
    </b-col>

    <b-col v-if="index === 0" cols="12">
      <p class="hp-p1-body">
        We are proud to announce our partnership with Genopets.
        <br /><br />
        In 2022 we purchased 917 Habitats for an average price of $136 each.
        <br /><br />
        These assets are available to our members and enable the earning
        mechanics inside of the Genopet Universe.
        <br /><br />
        The game itself is a free to play game, you can mint a baby genopet and
        level it up with only the Web3 gas fee required but if you want to take
        the game to the next level, your pet will need a home.
        <br /><br />
        This is where the Habitat comes in.
        <br /><br />
        Having the ability to earn the in game utility token Ki as well as the
        XP required to level up your pets.
        <br /><br />
        Habitats also have the ability to refine crystals which are used to
        repair the habitats and also craft powerups and cosmetics.
        <br /><br />
        As a guild, we take care of all the maintence and all the crafting.
        <br /><br />
        All you are required to do is walk each day, bank your steps in the game
        and convert that energy into Ki.
        <br /><br />
        We offer a different approach to most guilds because we are not focused
        on the earning aspect, rather the owning. That is why all guild members
        will OWN thier habitat once they successfully meet our terms.
      </p>
    </b-col>
    <how-it-works v-if="index === 1" />
    <dashboard v-else-if="index === 2" />
    <calculator v-else-if="index === 3" />
    <div v-else-if="index === 3">
      <h1>test</h1>
    </div>
    <admin v-else-if="index === 4" />
    <treasury v-else-if="index === 5" />
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BBadge, BAlert } from "bootstrap-vue";
import TabMenu from "./TabMenu.vue";
import Dashboard from "./Dashboard.vue";
import Admin from "./Admin.vue";
import Treasury from "./Treasury/index.vue";
import Calculator from "./Calculator.vue";
import HowItWorks from "./HowItWorks.vue";

export default {
  data() {
    return {
      index: 0,
      isOnboarded: false,
    };
  },
  components: {
    BRow,
    BCol,
    TabMenu,
    BButton,
    Dashboard,
    BBadge,
    Admin,
    BAlert,
    Treasury,
    Calculator,
    HowItWorks,
  },
  methods: {
    tabMenu(val) {
      this.index = val;
    },
  },
  mounted() {
    var urlSpecifiedTab = this.$route.query.tab;
    if (urlSpecifiedTab && urlSpecifiedTab >= 0 && urlSpecifiedTab <= 5) {
      this.index = Number(urlSpecifiedTab);
    }

    this.$watch("index", (val) => {
      this.$router.push({ query: { tab: val } });
    });
  },
};
</script>

<style scoped lang="scss">
.socialLogo {
  filter: invert(1);
}

.socialLogo:hover {
  filter: invert(0.5);
}

.mobile {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
