<template>
  <b-col>
    <b-row>
      <b-col cols="12" lg="6">
        <h2>Undisputed Noobs Genopets Treasury</h2>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-select
          v-model="treasurySelected"
          :options="options"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BAlert,
  BFormSelect,
} from "bootstrap-vue";

export default {
  data() {
    return {
      treasurySelected: "all",
      options: [
        { value: "all", text: "Global Treasury" },
        { value: "earth", text: "Treasury of Earth Wallet" },
        { value: "fire", text: "Treasury of Fire Wallet" },
        { value: "metal", text: "Treasury of Metal Wallet" },
        { value: "water", text: "Treasury of Water Wallet" },
        { value: "wood", text: "Treasury of Wood Wallet" },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    BAlert,
    BFormSelect,
  },
};
</script>
