<template>
  <b-col class="col-12 mb-0">
    <b-row>
      <b-col cols="12" lg="6" class="mb-32">
        <b-card class="overflow-hidden border-1">
          <p>
            <b>Getting Started with Genopets: </b>
          </p>

          <b-embed type="video" aspect="16by9" controls>
            <source
              src="https://sacul.cloud/img/3474975195.mp4"
              type="video/mp4"
            />
          </b-embed>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6">
        <p class="hp-p1-body" style="line-height: 2rem">
          When successfully onboarded into Genopets you will be assigned a
          Habitat.<br />
          The level you recieve will depend on your pet level and number of
          steps you can consistently compelte each day<br />
          Each Habitat is leased over a 6 month rental period (Level 2 and 3
          habtats have the ability to extend this lease period for additional
          profits)<br />
          The cost of the lease (maintenece) is front loaded which means you are
          responsible to cover the costs first before any profits can be
          removed.<br />
          Once costs are covered, you will convert into a 75 / 25 profit share
          weighted towards the member until such time as the cost of a habitat
          has been generated.<br />
          At this time the guild will mint you a habitat of your choice and you
          will OWN it. We will transfer it to your wallet and you can choose to
          use it or continue with the guild lease<br />
          If you continue with the Guild, you will move into a 50 / 50 profit
          split which is airdropped on a monthly basis until the lease ends.<br />
          Once a lease is complete you will have the option to continue with a
          new lease or retire as an elder community member.
        </p>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BAlert,
  BEmbed,
  BCard,
} from "bootstrap-vue";

export default {
  data() {
    return {
      index: 1,
      isOnboarded: false,
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    BAlert,
    BEmbed,
    BCard,
  },
};
</script>
