import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://api-unp.sacul.cloud/games/genopets/";

class GenopetsUser {
  async getUserHabitat(userWallet) {
    const response = await axios
      .get(API_URL + "getUserHabitat/" + userWallet, { headers: authHeader() })
      .then((response) => {
        if (response.data.success) {
          return {
            success: true,
            habitatData: response.data.habitatData,
            harvestData: response.data.harvestData,
          };
        } else {
          return {
            success: false,
            error: response.data.error,
            message: response.data.message,
          };
        }
      });
    return response;
  }

  async updateGenopetLevel(useruuid, newLevel) {
    var data = {
      useruuid: useruuid,
      newLevel: newLevel,
    };
    const response = await axios
      .post(API_URL + "updateBabyGenopetLevel", data, { headers: authHeader() })
      .then((response) => {
        if (response.data.success) {
          return {
            success: true,
            message: response.data.message,
          };
        } else {
          return {
            success: false,
            error: response.data.error,
            message: response.data.message,
          };
        }
      });
    return response;
  }

  async getHarvestEfficiency(harvestData, userWallet) {
    var data = [];
    for (var i in harvestData) {
      var player = harvestData[i].player;
      player = player.toLowerCase();
      userWallet = userWallet.toLowerCase();

      if (player == userWallet) {
        data.push(harvestData[i]);
      }
    }

    if (data == [] || data == 0) {
      return {
        success: false,
        message:
          "No harvest data found for this user, please harvest at least once to see data.",
      };
    }

    var totalHarvest = data.length;
    var latestHarvest = data[totalHarvest - 1].timestamp;
    var lastHarvest = data[0].timestamp;

    var timeDiff = lastHarvest - latestHarvest;
    var timeDiffInDays = timeDiff / (1000 * 3600 * 24);
    timeDiffInDays = Math.round(timeDiffInDays);

    // Calculate efficiency in percentage max 100%
    var efficiency = (totalHarvest / timeDiffInDays) * 100;
    efficiency = Math.round(efficiency);

    if (efficiency > 100) {
      efficiency = 100;
    } else if (efficiency < 0) {
      efficiency = 0;
    }

    // Calculate the number of harvests missed
    var harvestMissed = timeDiffInDays - totalHarvest;

    return {
      success: true,
      harvestEfficiency: efficiency,
      totalHarvest: totalHarvest,
      latestHarvest: latestHarvest,
      lastHarvest: lastHarvest,
      harvestMissed: harvestMissed,
    };
  }
}

export default new GenopetsUser();
